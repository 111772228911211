import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1280.000000 1146.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1146.000000) scale(0.100000,-0.100000)">
         <path d="M3985 6338 c-117 -8 -201 -31 -305 -83 -134 -67 -215 -140 -295 -265
-144 -223 -161 -529 -42 -765 81 -162 182 -262 352 -347 217 -109 529 -112
765 -6 36 16 93 47 128 70 l62 40 0 300 0 299 -102 -3 -103 -3 -3 -252 -2
-251 -78 -33 c-196 -81 -439 -70 -599 28 -135 83 -213 182 -259 327 -24 80
-26 97 -21 199 9 191 79 336 211 435 119 90 233 126 401 126 94 0 121 -4 190
-28 87 -30 128 -52 192 -103 23 -18 45 -33 50 -33 5 0 37 29 72 64 l63 63 -51
44 c-162 142 -353 196 -626 177z"/>
<path d="M4829 6287 c-46 -30 -81 -100 -73 -147 14 -74 48 -113 117 -132 137
-36 250 93 191 219 -38 80 -159 111 -235 60z"/>
<path d="M8487 6304 c-4 -4 -7 -346 -7 -761 l0 -753 85 0 85 0 0 70 c0 39 3
70 6 70 4 0 26 -18 49 -39 74 -68 186 -111 290 -111 105 1 241 43 304 95 133
109 184 220 184 400 1 95 -3 120 -26 184 -27 75 -89 167 -131 195 -13 8 -30
21 -37 28 -45 42 -193 87 -285 88 -109 0 -224 -42 -298 -110 l-46 -42 -2 343
-3 344 -80 3 c-45 1 -84 0 -88 -4z m615 -706 c78 -26 149 -96 187 -183 23 -53
26 -195 5 -257 -32 -95 -85 -154 -174 -197 -47 -22 -68 -26 -141 -25 -78 0
-91 3 -150 35 -120 66 -176 176 -167 331 9 163 101 277 249 308 50 10 143 4
191 -12z"/>
<path d="M5678 5785 c-96 -24 -205 -84 -274 -151 -220 -216 -217 -608 8 -822
127 -121 273 -170 477 -159 153 8 262 50 341 131 19 20 39 36 44 36 17 0 0
-221 -21 -273 -25 -62 -93 -142 -145 -168 -101 -52 -290 -60 -445 -20 -73 19
-215 84 -235 108 -6 7 -14 13 -18 13 -9 0 -100 -143 -100 -157 0 -24 133 -97
240 -131 102 -33 184 -44 325 -45 149 0 243 18 343 66 171 82 252 219 270 453
16 201 27 176 -122 270 l-128 82 -57 -57 c-151 -154 -402 -171 -579 -39 -99
74 -145 180 -140 323 3 73 9 102 32 152 74 161 273 250 470 210 41 -9 91 -23
111 -33 50 -24 131 -98 161 -149 l25 -43 97 35 c53 20 104 37 114 40 23 7 25
23 26 187 l0 146 -104 0 -104 0 0 -85 c0 -47 -1 -85 -3 -85 -2 0 -24 19 -49
43 -101 94 -227 137 -398 136 -58 0 -130 -7 -162 -14z"/>
<path d="M6923 5755 c-103 -22 -180 -74 -223 -148 -26 -45 -30 -62 -30 -126 0
-95 24 -149 86 -192 60 -41 101 -54 281 -89 196 -38 243 -64 243 -135 0 -46
-28 -85 -77 -107 -101 -44 -281 -30 -425 34 -40 18 -72 27 -78 22 -14 -15 -60
-106 -60 -119 0 -15 117 -68 201 -91 100 -27 294 -25 386 4 82 25 144 69 188
131 28 40 30 49 30 130 0 79 -3 92 -28 129 -37 57 -111 97 -227 122 -274 60
-275 60 -313 91 -33 27 -37 35 -37 78 0 87 75 131 224 131 97 0 181 -17 246
-50 l39 -20 30 57 c17 31 31 63 31 70 0 20 -103 63 -190 79 -94 17 -217 16
-297 -1z"/>
<path d="M4800 5281 l0 -481 120 0 120 0 -2 478 -3 477 -117 3 -118 3 0 -480z"/>
<path d="M7500 5445 c0 -333 5 -386 47 -469 51 -101 143 -162 286 -189 122
-23 283 23 368 105 21 20 41 33 44 27 4 -5 5 -35 4 -67 l-3 -57 80 0 79 0 3
483 2 482 -84 0 -84 0 -4 -302 c-4 -284 -5 -306 -25 -350 -56 -121 -147 -173
-293 -166 -123 6 -176 39 -223 138 -21 43 -22 62 -25 363 l-3 317 -85 0 -84 0
0 -315z"/>
<path d="M5797 5488 c-54 -15 -139 -96 -167 -160 -60 -141 21 -313 171 -361
28 -10 63 -17 78 -17 37 0 133 29 152 46 23 20 36 17 43 -10 8 -32 33 -40 63
-20 l25 16 -4 156 c-5 177 -12 199 -77 274 -67 76 -175 105 -284 76z m185
-108 c95 -64 113 -185 41 -271 -36 -42 -77 -60 -136 -60 -66 0 -98 12 -136 52
-115 120 -21 318 146 306 33 -2 64 -12 85 -27z"/>
<path d="M5846 5274 c-38 -37 -27 -98 19 -110 76 -19 118 49 67 109 -19 21
-65 22 -86 1z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
